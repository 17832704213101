<template>
  <b-card
      class="salesreturnbound-edit-wrapper"
  >

    <!-- form -->
    <ValidationObserver ref="observer">
      <b-form id="salesreturnboundForm" class="edit-form mt-2">
          <b-row>
            <b-col md="12">
              <div class="inner-card-title">基本信息</div>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="退货单编号"
                  label-for="returnbound_no"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="returnbound_no"
                    size="sm"
                    readonly
                    v-model="salesreturnbound.returnbound_no"
                />
              </b-form-group>
            </b-col>


            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="业务类型"
                  label-for="type_id"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="业务类型" #default="{ errors }">
                  <v-select
                      id="type_id"
                      :options="getCodeOptions('business_type')"
                      :clearable="true"
                      v-model="type_id"
                      @input="changeSelect('type_id',$event)"
                      class="select-size-sm"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="渠道"
                  label-for="channel_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="渠道" #default="{ errors }">
                  <b-form-input
                      id="channel_name"
                      size="sm"
                      v-model="salesreturnbound.channel_name"
                      @click="showSingleModal('渠道商')"
                      readonly
                      placeholder="点击搜索渠道商"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="销售主体"
                  label-for="company_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="渠道" #default="{ errors }">
                  <b-form-input
                      id="company_name"
                      size="sm"
                      v-model="salesreturnbound.company_name"
                      @click="showSingleModal('集团公司')"
                      readonly
                      placeholder="点击搜索集团公司"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="销售员"
                  label-for="sales_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="销售员" #default="{ errors }">
                  <b-form-input
                      id="sales_name"
                      size="sm"
                      readonly
                      v-model="salesreturnbound.sales_name"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>

              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="联系电话"
                  label-for="sales_mobile"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="销售员联系电话" #default="{ errors }">
                  <b-form-input
                      id="sales_mobile"
                      size="sm"
                      v-model="salesreturnbound.sales_mobile"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>

              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="退货原因"
                  label-for="reason"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="退货原因" #default="{ errors }">
                  <v-select
                      id="reason"
                      :options="getCodeOptions('sales_returnbound_reasontype')"
                      :clearable="true"
                      v-model="reason"
                      @input="changeSelect('reason',$event)"
                      class="select-size-sm"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>

              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="售后类型"
                  label-for="service"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="售后类型" #default="{ errors }">
                  <v-select
                      id="service"
                      :options="getCodeOptions('sales_returnbound_servicetype')"
                      :clearable="true"
                      v-model="service"
                      @input="changeSelect('service',$event)"
                      class="select-size-sm"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>

              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="入库仓仓库"
                  label-for="warehouse_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <ValidationProvider rules="required" name="入库仓仓库" #default="{ errors }">
                  <b-form-input
                      id="warehouse_name"
                      size="sm"
                      v-model="salesreturnbound.warehouse_name"
                      @click="showSingleModal('入库仓库')"
                      readonly
                      placeholder="点击搜索入库仓库"
                      :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>

              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="退货时间"
                  label-for="return_time"
                  label-size="sm"
                  :class="'mb-1 required'"
              >
                <ValidationProvider :rules="required" name="退货时间" #default="{ errors }">
                  <flat-pickr
                      id="return_time"
                      v-model="return_time"
                      @on-change="dateChange('return_time')"
                      class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>


              </b-form-group>
            </b-col>

            <!--退货金额 实时计算-->
            <b-col md="3">
              <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="退货金额"
                      label-for="return_time"
                      label-size="sm"
                      :class="'mb-1'"
              >
              <b-form-input
                        id="sales_amount"
                        size="sm"
                        v-model="returnTotal"
                        readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <div class="inner-card-title">退货信息</div>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="费用承担方"
                  label-for="responsibility"
                  label-size="sm"
                  class="mb-1"
              >

                  <v-select
                      id="responsibility"
                      :options="getCodeOptions('sales_returnbound_responsibility')"
                      :clearable="true"
                      v-model="responsibility"
                      @input="changeSelect('responsibility',$event)"
                      class="select-size-sm"
                  >
                  </v-select>



              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="物流方式"
                  label-for="delivery_type"
                  label-size="sm"
                  class="mb-1"
              >

                  <v-select
                      id="delivery_type"
                      :options="getCodeOptions('delivery_method_sale')"
                      :clearable="true"
                      v-model="deliveryType"
                      @input="changeSelect('delivery_type',$event)"
                      class="select-size-sm"
                  />

              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="承运商"
                  label-for="carrier"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="carrier"
                    size="sm"
                    v-model="salesreturnbound.carrier"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="快递单号"
                  label-for="express_no"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="express_no"
                    size="sm"
                    v-model="salesreturnbound.express_no"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="收货方联系人"
                  label-for="our_contact"
                  label-size="sm"
                  class="mb-1"
              >

                  <b-form-input
                      id="our_contact"
                      size="sm"
                      readonly
                      v-model="salesreturnbound.our_contact"
                  />


              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="联系电话"
                  label-for="our_mobile"
                  label-size="sm"
                  class="mb-1"
              >

                  <b-form-input
                      id="our_mobile"
                      size="sm"
                      v-model="salesreturnbound.our_mobile"

                  />


              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="退货方联系人"
                  label-for="other_contact"
                  label-size="sm"
                  class="mb-1"
              >

                  <b-form-input
                      id="other_contact"
                      size="sm"
                      v-model="salesreturnbound.other_contact"

                  />


              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="联系电话"
                  label-for="other_mobile"
                  label-size="sm"
                  class="mb-1"
              >

                  <b-form-input
                      id="other_mobile"
                      size="sm"
                      v-model="salesreturnbound.other_mobile"

                  />


              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="发货时间"
                  label-for="delivery_time"
                  label-size="sm"
                  class="mb-1"
              >

                  <flat-pickr
                      id="delivery_time"
                      v-model="delivery_time"
                      @on-change="dateChange('delivery_time')"
                      class="form-control"
                  />


              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="预计到货时间"
                  label-for="arrival_time"
                  label-size="sm"
                  class="mb-1"
              >

                  <flat-pickr
                      id="arrival_time"
                      v-model="arrival_time"
                      @on-change="dateChange('arrival_time')"
                      class="form-control"
                  />



              </b-form-group>
            </b-col>
            <b-col md="12">
              <div class="inner-card-title">明细列表</div>
            </b-col>
<!--  yangTest          -->
            <b-col md="12">
              <sales-returnbound-item-list :returnbound_id="salesreturnbound.returnbound_id" v-if="itemListShowFlag"
                                           ref="itemList" v-on:allReturnTotal="allReturnTotalMethod"
              >

              </sales-returnbound-item-list>
            </b-col>

            <b-col md="12" class="mt-2">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="备注"
                  label-for="remark"
                  label-size="sm"
                  class="mb-1"
              >

                  <b-form-textarea
                      id="remark"
                      size="sm"
                      v-model="salesreturnbound.remark"
                      rows="3"
                      max-rows="6"

                  />

              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="附件上传"
                  label-for="attachments"
                  label-size="sm"
                  :class="'mb-1'"
              >
                <attachment-upload v-if="salesreturnbound.loaded" :theme="'files'"
                                   :attachment_id="'attachments'"
                                   :id="salesreturnbound.attachments"
                                   :object_type="'sales_returnbound'"
                                   :object_id="salesreturnbound.returnbound_id"
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>


          </b-row>
      </b-form>
    </ValidationObserver>
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <company-select
          ref="companySelect" :companyType="companyType" v-if="modalName === '集团公司' || modalName === '渠道商'"
      >
      </company-select>

      <warehouse-list
          ref="warehouseSelect" :type="1" v-else
      >
      </warehouse-list>


    </b-modal>
    <!--/ form -->

    <b-col
        cols="12"
        class="mt-50"
    >
      <b-button
          variant="success"
          class="mr-1"
          @click="save"
          v-if="readonly===0"
      >
        保存
      </b-button>
      <b-button
          variant="outline-secondary"
          class="mr-1"
          @click="cancel"
      >
        取消
      </b-button>
      <b-button
          variant="primary"
          class="mr-1"
          @click="saveAndCommit"
          v-if="readonly===0"
      >
        提交
      </b-button>
    </b-col>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import salesreturnboundStore from './salesreturnboundStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second,
  addNumber
} from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import WarehouseList from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import SalesReturnboundItemList from '@/views/apps/salesreturnbounditem/SalesReturnboundItemList'
import salesreturnbounditemStore from '@/views/apps/salesreturnbounditem/salesreturnbounditemStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    SalesReturnboundItemList,
    WarehouseList,
    CompanySelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    AttachmentUpload,
  },
  props: {
    outbId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      id: ref(0),
      outbound_id: 0,
      salesreturnbound: ref({}),
      deliveryType: {},
      type_id: {},
      companyType: '',
      modalName: '',
      reason: {},
      service: {},
      responsibility: {},
      delivery_time: '',
      return_time:'',
      arrival_time: '',
      required:'required',
      returnTotal:0,
      readonly:0,
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    saveItem() {
      let items = this.$refs.itemList.dataList
      let res = []
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        let response = store.dispatch('salesreturnbounditem/updateItem', {
          itemId: item.returnbounditem_id,
          qty: item.salesreturnqty,
          productCostTax:item.product_cost_tax,
        })
        res.push(response)
      }
      return res
    },
    dateChange(key) {
      this.salesreturnbound[key] = second(this[key])
    },
    responsiveSet(key, value) {
      this.$set(this.salesreturnbound, key, value)
    },
    changeSelect(key, event) {
      this.salesreturnbound[key] = event == null ? undefined : event.value
     // this.required=this.salesreturnbound.reason==5?'':'required'
    },
    showSingleModal(modalName) {
      if (modalName === '集团公司') {
        this.companyType = 'Group'
      } else if (modalName === '渠道商') {
        this.companyType = 'ChannelCustomer'
      }
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },
    onSelectSingle() {
      let data = {}
      // this.$refs.itemList.refetchData();
      switch (this.modalName) {
        case '集团公司': {
          data = this.$refs.companySelect.getSelected()[0]
          this.responsiveSet('company_id', data.company_id)
          this.responsiveSet('company_name', data.company_name)
          break
        }
        case '渠道商': {
          data = this.$refs.companySelect.getSelected()[0]
          this.salesreturnbound.channel_name = data.company_name
          this.salesreturnbound.channel_id = data.company_id
          /*this.responsiveSet('channel_id',data.company_id)
          this.responsiveSet('channel_name',data.company_name)*/
          break
        }
        case '入库仓库': {
          data = this.$refs.warehouseSelect.getSelected()[0]
          this.responsiveSet('warehouse_id', data['warehouse_id'])
          this.responsiveSet('warehouse_name', data['warehouse_name'])
          this.responsiveSet('our_contact', data['contact'])
          this.responsiveSet('our_mobile', data['mobile'])
          break
        }
        default: {
          break
        }
      }
    },

  },
  setup() {
    const toast = useToast()
    const itemListShowFlag = ref(false)
    const userData = getUserData()
    // Register module
    if (!store.hasModule('salesreturnbound')) store.registerModule('salesreturnbound', salesreturnboundStore)
    if (!store.hasModule('salesreturnbounditem')) store.registerModule('salesreturnbounditem', salesreturnbounditemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesreturnbound')) store.unregisterModule('salesreturnbound')
      if (store.hasModule('salesreturnbounditem')) store.unregisterModule('salesreturnbounditem')
    })

    const edit = function () {
      store.dispatch('salesreturnbound/edit', {
        id: this.id,
        outbound_id: this.outbound_id
      })
          .then(res => {
            if (res.data.code==1){
              toast.error(res.data.data)
              if(this.outbId !== undefined && this.outbId !== 0){
                this.$emit('refesh')
              }else{
                this.$router.push({ name: 'apps-salesreturnbound-list' })
              }
            }
            // console.log(res)
            this.salesreturnbound = res.data.data
            itemListShowFlag.value = true
            if (this.id !== 0) {
              this.type_id = getCode('business_type', this.salesreturnbound.type_id)

              this.deliveryType = getCode('delivery_method_purchase', this.salesreturnbound.delivery_type)
              this.reason = getCode('sales_returnbound_reasontype', this.salesreturnbound.reason)
              this.service = getCode('sales_returnbound_servicetype', this.salesreturnbound.service)
              this.responsibility = getCode('sales_returnbound_responsibility', this.salesreturnbound.responsibility)
              this.delivery_time = toDate(this.salesreturnbound.delivery_time)
              this.return_time = toDate(this.salesreturnbound.return_time)
              this.arrival_time = toDate(this.salesreturnbound.arrival_time)
              this.required=this.salesreturnbound.reason==5?'':'required'
            } else {
              //
              this.return_time = toDate(this.salesreturnbound.return_time)
              this.type_id = getCode('business_type', this.salesreturnbound.type_id)
              this.service = getCode('sales_returnbound_servicetype', this.salesreturnbound.service)
              this.salesreturnbound.sales_id = userData.user_id
              this.salesreturnbound.sales_name = userData.full_name
              this.salesreturnbound.sales_mobile = userData.mobile
            }
          })
    }

    const view = function () {
      store.dispatch('salesreturnbound/view', { id: this.id })
          .then(res => {
            this.salesreturnbound = res.data.data
          })
    }

    const cancel = function () {
      if(this.outbId !== undefined && this.outbId !== 0){
        this.$emit('refesh')
      }else{
        this.$router.go(-1)
      }
    }

    const save = async function () {
      if (this.salesreturnbound.status > 0) {
        toast.error('当前状态不可修改')
        return
      }
      //子表字段校验
      let validateItem = await this.$refs.itemList.$refs.observerItemList.validate()

      let validate = await this.$refs.observer.validate()

      if (this.salesreturnbound.reason==undefined || this.salesreturnbound.service==undefined){
        toast.error('请填写退货原因或者售后类型')
        return
      }

      let items = this.$refs.itemList.dataList
      let resultIsZero = true
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        let qty = item.salesreturnqty
        if(qty>0){
          resultIsZero = false
        }
      }
      if (resultIsZero){
        toast.error("预计退货数量不能为0，请重新填写！")
        return
      }

      if (validateItem && validate) {
        let promises = this.saveItem()
        promises.push(store.dispatch('salesreturnbound/save', this.salesreturnbound))
        let resList = await Promise.all(promises)

        if (resList.filter(item => item.data.code === 1).length === 0) {
          toast.success('数据已保存!')
          if(this.outbId !== undefined && this.outbId !== 0){
            this.$emit('refesh')
          }else{
            this.$router.push({ name: 'apps-salesreturnbound-list' })
          }
        } else {
          for (let i = 0; i < resList.length; i++) {
            let res = resList[i]
            let prefix = res.config.url.indexOf('salesreturnbounditem') === -1 ? '表单数据' : `产品#${res.data.data.returnbounditem_id}`
            if (res.data.code === 0) {
              toast.success(`${prefix}保存成功`)
            } else {
              toast.error(`${prefix}保存失败`)
            }
          }
          this.$refs.itemList.refetchData()
        }
      } else {
        toast.error('请正确填写字段')
      }

    }

    const onUploaded = function (id, attachment, result) {
      this.salesreturnbound[id] = result
    }

    const saveAndCommit = async function () {
      if (this.salesreturnbound.status > 0) {
        toast.error('当前状态不可修改')
        return
      }
      //子表字段校验
      let validateItem = await this.$refs.itemList.$refs.observerItemList.validate()

      let validate = await this.$refs.observer.validate()

      if (this.salesreturnbound.reason==undefined || this.salesreturnbound.service==undefined){
        toast.error('请填写退货原因或者售后类型')
        return
      }
      let items = this.$refs.itemList.dataList
      let resultIsZero = true
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        let qty = item.salesreturnqty
        if(qty>0){
          resultIsZero = false
        }
      }

      if (resultIsZero){
        toast.error("预计退货数量不能为0，请重新填写！")
        return
      }
      if (validateItem && validate) {
        let promises = this.saveItem()
        promises.push(store.dispatch('salesreturnbound/save', this.salesreturnbound))
        let resList = await Promise.all(promises)
        if (resList.filter(item => item.data.code === 1).length === 0) {
          toast.success('数据已保存!')
          store.dispatch('salesreturnbound/commit', {returnboundId: this.salesreturnbound.returnbound_id})
              .then((res) => {
                if (res.data.code === 0) {
                  toast.success('提交成功')
                  if(this.outbId !== undefined && this.outbId !== 0){
                    this.$emit('refesh')
                  }else{
                    this.$router.push({ name: 'apps-salesreturnbound-list' })
                  }
                }else{
                  toast.error(res.data.data)
                  if(this.outbId !== undefined && this.outbId !== 0){
                    this.$emit('refesh')
                  }else{
                    this.$router.push({ name: 'apps-salesreturnbound-list' })
                  }
                }
              })
        } else {
          for (let i = 0; i < resList.length; i++) {
            let res = resList[i]
            let prefix = res.config.url.indexOf('salesreturnbounditem') === -1 ? '表单数据' : `产品#${res.data.data.returnbounditem_id}`
            if (res.data.code === 0) {
              toast.success(`${prefix}保存成功`)
            } else {
              toast.error(`${prefix}保存失败`)
            }
          }
          this.$refs.itemList.refetchData()
        }
      } else {
        toast.error('请正确填写字段')
      }

    }

    const regItem = function () {
      let items = this.$refs.itemList.dataList
      let res = []
      for (let i = 0; i < items.length; i++) {
        let item = items[i]
        let qty = item.salesreturnqty
        if(qty===0){
          toast.error("预计退货数量不能为0，请重新填写！")
          return
        }
      }
    }

    const  allReturnTotalMethod = function (val) {
      this.returnTotal = val;
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      itemListShowFlag,
      userData,
      onUploaded,
      saveAndCommit,
      regItem,
      allReturnTotalMethod

    }
  },
  created() {
    if(this.outbId !== undefined && this.outbId !== 0){
      this.id = 0
      this.outbound_id = this.outbId
    }else{
      this.id = this.$route.query.id || 0
      this.outbound_id = this.$route.query.outbound_id || 0
    }
    this.readonly = this.$route.query.readonly || 0;
    if (this.$route.query.readonly===0||this.id===0){
      this.readonly=0
    }
    this.edit()
  },
  watch: {
    itemListShowFlag: {
      handler() {
        this.allReturnTotalMethod()
      }
    },
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
